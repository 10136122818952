// src/core/redux/slices/dividends/userAssetsSlice.ts

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Connection, PublicKey } from "@solana/web3.js";
import { getAccount, getAssociatedTokenAddress } from "@solana/spl-token";
import { PinxAssets } from "../../../../constants/PinxAssets";

const connection = new Connection("https://api.devnet.solana.com");

export const fetchUserAssets = createAsyncThunk(
  "userAssets/fetch",
  async (publicKey: PublicKey) => {
    const balances: Record<string, number> = {};

    for (const asset of PinxAssets) {
      const mintAddress = asset.devnetMintAddress;
      if (!mintAddress) continue;

      try {
        if (mintAddress === "So11111111111111111111111111111111111111112") {
          // Native SOL balance
          const lamports = await connection.getBalance(publicKey);
          balances[asset.symbol] = lamports / Math.pow(10, asset.decimals);
        } else {
          // SPL token balance
          const mintPubkey = new PublicKey(mintAddress);
          const ata = await getAssociatedTokenAddress(mintPubkey, publicKey);
          const account = await getAccount(connection, ata);
          balances[asset.symbol] = Number(account.amount) / Math.pow(10, asset.decimals);
        }
      } catch {
        balances[asset.symbol] = 0; // If no account, default to 0
      }
    }

    return balances;
  }
);

const userAssetsSlice = createSlice({
  name: "userAssets",
  initialState: {
    balances: {} as Record<string, number>,
    loading: false,
    error: null as string | null,
  },
  reducers: {
    resetUserAssets(state) {
      state.balances = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAssets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.balances = action.payload;
      })
      .addCase(fetchUserAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to load balances";
      });
  },
});

export const { resetUserAssets } = userAssetsSlice.actions;
export default userAssetsSlice.reducer;
