import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const governanceApi = createApi({
  reducerPath: 'governanceApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getProposals: builder.query<any[], void>({
      queryFn: async () => {
        try {
          const res = await fetch(
            'https://fvxr3qmhz1.execute-api.eu-north-1.amazonaws.com/default/Pinx-FetchGovernanceProposals'
          );
          const data = await res.json();
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    getVotingHistory: builder.query<any[], void>({
      queryFn: async () => {
        try {
          const res = await fetch(
            'https://okqgn3tah2.execute-api.eu-north-1.amazonaws.com/default/Pinx-FetchUserVotingHistory'
          );
          const data = await res.json();
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetProposalsQuery, useGetVotingHistoryQuery } = governanceApi;
