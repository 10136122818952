import { configureStore } from '@reduxjs/toolkit';
import swapReducer from './slices/swapSlice';
import balanceReducer from './slices/balancesSlice';
import conversionRateReducer from './slices/conversionRateSlice';
import userAssetsReducer from './slices/dividends/userAssetsSlice';
import { dividendsApi } from './api/dividendsApi';
import { governanceApi } from './api/governanceApi'; // ✅ Import governanceApi

const store = configureStore({
  reducer: {
    swap: swapReducer,
    balances: balanceReducer,
    conversionRate: conversionRateReducer,
    userAssets: userAssetsReducer,
    [dividendsApi.reducerPath]: dividendsApi.reducer,
    [governanceApi.reducerPath]: governanceApi.reducer, // ✅ Add governanceApi reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(dividendsApi.middleware)
      .concat(governanceApi.middleware), // ✅ Add governanceApi middleware
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
