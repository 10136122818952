//EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v

import solanaLogo from '../../public/assets/SOL-logo.png';
import usdcLogo from '../../public/assets/usdc-logo.png';
import tmLogo from '../../public/assets/tm-logo.png';
import nkeLogo from '../../public/assets/nke-logo.png';
import hmcLogo from '../../public/assets/hmc-logo.png';
import intcLogo from '../../public/assets/intc-logo.png';
import fLogo from '../../public/assets/f-logo.png';
import amznLogo from '../../public/assets/amzn-logo.png';
import googlLogo from '../../public/assets/googl-logo.png';
import tslaLogo from '../../public/assets/tsla-logo.png';

export const PinxAssets = [
  { 
    title: 'Solana', 
    symbol: 'SOL', 
    mintAddress: 'So11111111111111111111111111111111111111112',
    devnetMintAddress: 'So11111111111111111111111111111111111111112',
    image: solanaLogo,
    decimals: 9 
  },
  { 
    title: 'USD Coin', 
    symbol: 'USDC', 
    mintAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    devnetMintAddress: 'BHnj1Vcvo3BJYrQuSficwDrBrbg8g4aWHYxfakm6MrTL',
    image: usdcLogo,
    decimals: 6,
    devnetDecimals: 9 
  },
  { 
    title: 'Toyota', 
    symbol: 'TM', 
    mintAddress: '',
    devnetMintAddress: '',
    image: tmLogo,
    decimals: 9 
  },         
  { 
    title: 'Nike', 
    symbol: 'NKE', 
    mintAddress: '',
    devnetMintAddress: '',
    image: nkeLogo,
    decimals: 9 
  },          
  { 
    title: 'Honda', 
    symbol: 'HMC', 
    mintAddress: '',
    devnetMintAddress: '',
    image: hmcLogo,
    decimals: 9 
  },        
  { 
    title: 'Intel', 
    symbol: 'INTC', 
    mintAddress: '',
    devnetMintAddress: '',
    image: intcLogo,
    decimals: 9 
  },        
  { 
    title: 'Ford', 
    symbol: 'F', 
    mintAddress: '',
    devnetMintAddress: '',
    image: fLogo,
    decimals: 9 
  },            
  { 
    title: 'Amazon', 
    symbol: 'AMZN', 
    mintAddress: 'EXkUAKfa2JzVnGHAAdHHsPbMRpDb5gb2tfEgFc6f57Lw',
    devnetMintAddress: 'EXkUAKfa2JzVnGHAAdHHsPbMRpDb5gb2tfEgFc6f57Lw',
    image: amznLogo,
    decimals: 9 
  },       
  { 
    title: 'Google', 
    symbol: 'GOOGL', 
    mintAddress: '',
    devnetMintAddress: '',
    image: googlLogo,
    decimals: 9 
  },      
  { 
    title: 'Tesla', 
    symbol: 'TSLA', 
    mintAddress: 'E2XuXTJMXU2TjJ9pmZwsEiUSRa56mMvrocEXhT1VKBU',
    devnetMintAddress: 'E2XuXTJMXU2TjJ9pmZwsEiUSRa56mMvrocEXhT1VKBU',
    image: tslaLogo,
    decimals: 9 
  }
];
