import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type DividendPoolResponse = {
  dividend_pool: string;
  reward_mint: string;
  tokenized_stock: string;
  decimals: number;
};

export const dividendsApi = createApi({
  reducerPath: 'dividendsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://gkeu7304si.execute-api.eu-north-1.amazonaws.com/default/',
  }),
  endpoints: (builder) => ({
    // ✅ Claimed dividends (uses default baseUrl)
    getClaimedDividends: builder.query<any[], string>({
      query: (publicKey: string) =>
        `Pinx-FetchUserDividendClaimed?publicKey=${publicKey}`,
    }),

    // ✅ Dividend pools (uses full URL because it's from a different API Gateway)
    getDividendPools: builder.query<DividendPoolResponse[], void>({
      query: () => ({
        url: 'https://9u9gki0b0m.execute-api.eu-north-1.amazonaws.com/default/Pinx-FetchDividendPools',
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 10, // cache for 10 minutes
    }),
  }),
});

export const {
  useGetClaimedDividendsQuery,
  useGetDividendPoolsQuery,
} = dividendsApi;
